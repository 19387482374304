.mb-7px {
    margin-bottom: 7px;
}

.backGround {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 100%;
    position: absolute;
    transition: all 0.5s ease-out;
}

li {
    list-style: none;
}

.py-3px {
    padding-top: 3px;
    padding-bottom: 3px;
}

.py-5px {
    padding-top: 5px;
    padding-bottom: 5px;
}

.mb-3px {
    margin-bottom: 3px;
}

.colorPrimary {
    color: #7367f0;
}

.zIndex0 {
    z-index: 0;
}

.hoverRows:hover {
    background-color: #f3f3f3;
}

.hoverRowsDark:hover {
    background-color: #3b4253;
}

.iconUserDetail {
    border: solid 2px;
    border-radius: 50%;
}

.text-align-center {
    display: block;
    text-align: center;
    width: 100%;
}

.width60 {
    width: 60%;
}

.width20 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.floatRight {
    align-self: flex-end
}

.badgeStyle {
    background-color: transparent;
    border: solid 1px #7367f0;
    color: #7367f0;
}

a {
    color: #5E5873;
}

a:hover {
    color: #5E5873;
}

.backGroundNavList {
    width: 100%;
    height: 100vh;
    background-color: rgba(34, 41, 47, .5);
    position: absolute;
    transition: all 0.5s;
}