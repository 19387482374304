.logoNav {
    width: 40px;
}

.txtLogo {
    color: #7921d9 !important;
}

.togglerItem {
    margin-top: -3px;
    margin-right: 8px;
}